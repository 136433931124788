<div class="header-container">
  <div class="logo-container">
    <a *ngIf="owner!= 'MEKATECHNOAUTH'" (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a [ngSwitch]="owner" class="logo" href="#" (click)="navigateHome()">
      <ng-container *ngSwitchCase="'ANME'">
        <img *ngIf="getConnectedId() != 31" src="https://traci.tn/images/ESMES.png"  style="width: 10rem;">
        <img *ngIf="getConnectedId() == 31" src="https://traci.tn/images/issat_logo.png" style="width: 5rem;">
      </ng-container>

      <ng-container *ngSwitchCase="'KASSAB'">
        <img *ngIf="getConnectedId() != 54" src="assets/images/green-kassab.png"  style="width: 10rem;">
        <img *ngIf="getConnectedId() == 54" src="assets/images/kassab.png" style="width: 5rem;">
      </ng-container>
      <ng-container *ngSwitchCase="'AZIZA'">
        <img src="https://traci.tn/images/aziza_g.png" style="width: 2.5rem;">
        <span class="mr-2">AZIZA</span>
      </ng-container>
      <span *ngSwitchCase="'DEPOT'">DEPOT</span>
      <ng-container *ngSwitchCase="'FLEETDEPOT'">
        <img src="https://fleet.tn/images/logotds.png" style="width: 3.5rem;">
      </ng-container>
      <span *ngSwitchCase="'TRICITY'">TRICITY</span>
      <span *ngSwitchCase="'IOT'">IOT</span>
      <span *ngSwitchCase="'INPUT'">
        <span  *ngIf="getConnectedId() == 32" >DRE Monastir</span>
        <span  *ngIf="getConnectedId() == 48" >DEMO</span>
     </span>
     <span *ngSwitchCase="'METOS'" >
      <span *ngIf="getConnectedId() == 30" (click)="goToHome()"  >METOS</span>
      <span *ngIf="getConnectedId() > 30" (click)="goToHome()"  >GLOBETECH</span>
    </span>
    <span *ngSwitchCase="'MEKATECH'">MEKATECH</span>
    <span *ngSwitchCase="'MEKATECHNOAUTH'">MEKATECH</span>
    <span *ngSwitchCase="'VILAVI'">VILAVI</span>
    </a>
    <span *ngIf="isSuperAdmin()" class="mt-3 text-muted" style="font-weight: 600;"> Admin</span>
  </div>
</div>

<div *ngIf="owner!= 'MEKATECHNOAUTH'"  class="header-container">
  
  <!-- <select (change)="translateLanguageTo(selLang.value)">
    <option *ngFor="let language of translate.getLangs()" [value]="language">{{ language  }}</option>
  </select> -->


  <nb-actions size="small">
    <nb-action  status="control" nbTooltip="{{'TOOLTIP.lang' | translate }}" nbTooltipPlacement="top" class="control-item" >
      <nb-select size="tiny" filled shape="semi-round" outline="false" status="primary" [(selected)]="selectedLanguage"  
      placeholder="lang" (selectedChange)="translateLanguageTo($event)" class="pointer lang-select">
      <nb-option *ngFor="let language of translate.getLangs()" [value]="language">{{ language | uppercase}}</nb-option>
    </nb-select>
    </nb-action>
    <nb-action status="control" nbTooltip="{{'TOOLTIP.notif' | translate }}" nbTooltipPlacement="left" class="control-item" icon="bell-outline"></nb-action>
    <nb-action (click)="logout()" status="control" nbTooltip="{{'TOOLTIP.logout' | translate }}" nbTooltipPlacement="top" class="control-item" icon="log-out-outline"></nb-action>

    <!-- <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
    </nb-action> -->
  </nb-actions>
</div>
