///VERSION METOS
export var dns: string = "/ws_tricity/";
//VERSION ADMINGLOBTECH
// export var dns: string = "/ws_tricity2/";
//  export var dns : string = "http://localhost:8080/";
// export var dns : string = "https://adminglobetech.ttn.tn/ws_tricity2/";
// export var dns: string = "https://globetech.ttn.tn/ws_tricity/";
export var time_difference = new Date().getTimezoneOffset();



export var version: string = '8.0.6_041224';

export var owner: string = 'METOS';

// VERSION ADMINGLOBTECH
// export var themeName: string ='blueOne';

//VERSION METOS
export var themeName: string ='redOne';


