import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'RealTime', // 'Temps réel',
    icon: 'home-outline',
    link: '/pages/realtime/dashboard',
    home: true,
    data: 'realTime',
  },
  {
    title: 'History',//'Historique',
    icon: 'bar-chart',
    data: 'history',
    link: '/pages/historical/comparator'
    },
  {
    title: 'Settings', // 'PARAMETRAGE',
    icon: 'settings-2-outline',
    data:'settings',
    children: [
      {
        title: 'Station',
        link: '/pages/data-management/station',
        data: 'stationManagmt',
      },
      {
        title: 'Sensor',
        link: '/pages/data-management/sensor',
        data: 'sensorManagmt',

      },
      {
        title: ' Importation des données',
        link: '/pages/data-management/import'
      }
    ]
  },
  {
  title: 'Alerts',
  icon: 'alert-circle-outline',
  data: 'alerts',
   children: [
    {
      title: 'Alerts management ', //'Gestion des alertes',
      link: '/pages/alerts',
      data: 'alertManagmt',
     },
    {
      title: 'Alerts history', //'Historique des alertes',
      link: '/pages/alerts/history',
      data: 'alertHist',
     },
  ]
},
{
  title: 'Disconnection',
  icon: 'log-out-outline',
  link: '/signin',
  queryParams: { 'logout': '' },
  data: 'logout',
}
];
