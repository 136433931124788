
export interface ISmartTableData {
   getData(): any[];
}


export class SmartTableData {
  getData(): any[]{
    return [];
  }
}